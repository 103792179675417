import React, { useState, useEffect } from "react"
import Col from "react-bootstrap/Col"
import { useSelector, useDispatch } from "react-redux"
import { ActualizaPreguntas } from '../../state/actions/Faq/FaqActions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import BeneficiariosFonasa from './BeneficiariosFonasa';
import BeneficiariosIsapre from './BeneficiariosIsapre';
import PrestadoresSalud from './PrestadoresSalud';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '100%',
      width: '100%',
      backgroundColor: '#0073ba ',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    
    color: 'gray',
    fontWeight:  "bold",
   
  },
}))((props) => <Tab disableRipple {...props} />);

const FaqComponent = () => {
  const [TipoPreguntaId, setTipoPreguntaId] = useState(0)
  const [key, setKey] = useState(1)
  const [PreguntaSelect, setPreguntaSelect] = useState(0)
  const PreguntasFrecuentes = useSelector(
    state => state.Faq.PreguntasFrecuentes
  )
  const TipoPregunta = useSelector(state => state.Faq.TipoPregunta)
  const PreguntasMap = useSelector(state => state.Faq.PreguntasMap)
  
  const dispatch = useDispatch()

  useEffect(() => {
    const ActualizarPreguntas = () => dispatch(ActualizaPreguntas(PreguntasFrecuentes.filter(func => func.id_tipo_pregunta === 1)))
    ActualizarPreguntas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const ChangeSelect = e => {

    e.preventDefault()
    setTipoPreguntaId(Number(e.target.value))
   
    const ActualizarPreguntas = () => dispatch(ActualizaPreguntas(PreguntasFrecuentes.filter(func => func.id_tipo_pregunta === Number(e.target.value))))
    ActualizarPreguntas()
  }

  const handleAutocomplete = (event, value) => {
    event.preventDefault()
    if (value !== null) {

      setPreguntaSelect(value.id_pregunta)
      
      const ActualizarPreguntas = () => dispatch(ActualizaPreguntas(PreguntasFrecuentes.filter(func => func.id_pregunta === value.id_pregunta)))
      ActualizarPreguntas()
    } else {
      setPreguntaSelect(0)
      const ActualizarPreguntas = () => dispatch(ActualizaPreguntas(PreguntasFrecuentes.filter(func => func.id_tipo_pregunta === TipoPreguntaId)))
      ActualizarPreguntas()

    }
  }

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setKey(parseInt(newValue))
    setTipoPreguntaId(0)
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Col xl="12" className="my-2">
        <h3 className="text-center font-weight-bold titulo-antecedentes-ss">¿Qué deseas consultar?</h3>
        <small className="text-center d-block">
          Selecciona uno de los tres tipos de consultas frecuentes
        </small>
      </Col>


      <div className={classes.root}>
      <AppBar position="static" color="default">
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <StyledTab  label="Beneficiarios Fonasa" {...a11yProps(0)} />
          <StyledTab  label="Beneficiarios Isapre" {...a11yProps(1)} />
          <StyledTab  label="Prestadores de Salud" {...a11yProps(2)} />
        </StyledTabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>

          <BeneficiariosFonasa 
            ChangeSelect={ChangeSelect}
            TipoPreguntaId={TipoPreguntaId}
            TipoPregunta={TipoPregunta}
            key={key}
            PreguntasMap={PreguntasMap}
            handleAutocomplete={handleAutocomplete}
            PreguntaSelect={PreguntaSelect}
          />

        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          
        <BeneficiariosIsapre 
            ChangeSelect={ChangeSelect}
            TipoPreguntaId={TipoPreguntaId}
            TipoPregunta={TipoPregunta}
            key={key}
            PreguntasMap={PreguntasMap}
            handleAutocomplete={handleAutocomplete}
            PreguntaSelect={PreguntaSelect}
          />

        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          
        <PrestadoresSalud 
            ChangeSelect={ChangeSelect}
            TipoPreguntaId={TipoPreguntaId}
            TipoPregunta={TipoPregunta}
            key={key}
            PreguntasMap={PreguntasMap}
            handleAutocomplete={handleAutocomplete}
            PreguntaSelect={PreguntaSelect}
          />

        </TabPanel>
      </SwipeableViews>
    </div>

    </>
  )
}

export default FaqComponent
