import React, {useState} from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Card from "react-bootstrap/Card"
import Accordion from "react-bootstrap/Accordion"

const PrestadoresSalud = ({
  ChangeSelect,
  TipoPreguntaId,
  TipoPregunta,
  PreguntasMap,
  PreguntaSelect,
}) => {

    
    const [Preguntado, setPreguntado] = useState(0)
    
  const handleAutocomplete = (event, value) => {
    event.preventDefault()
    if (value !== null) {
      setPreguntado(value.id_pregunta)
    } else {
      setPreguntado(0)
    }
  }

  return (
    <>
      <p className="font-weight-bold">
        Selecciona el tema relacionado a tu pregunta
      </p>

      <FormControl fullWidth variant="outlined">
        <InputLabel className=" bg-white">Tipo Pregunta</InputLabel>
        <Select
          value={TipoPreguntaId}
          onChange={ChangeSelect}
          label="tipo_pregunta"
        >
          <MenuItem value={0}>Seleccione</MenuItem>
          {TipoPregunta.length !== 0
            ? TipoPregunta.filter(func => func.id_seccion === 3).map(
                (dato, i) => (
                  <MenuItem value={dato.id_tipo_pregunta} key={i}>
                    {dato.desc_tipo_pregunta}
                  </MenuItem>
                )
              )
            : null}
        </Select>
      </FormControl>

      <p className="font-weight-bold mt-3">Selecciona una pregunta</p>
      {PreguntasMap !== "undefined" && PreguntasMap !== "" && (
        <Autocomplete
          options={PreguntasMap}
          getOptionLabel={option => option.desc_pregunta}
          noOptionsText="Sin resultados"
          defaultValue={0}
          renderInput={params => (
            <TextField {...params} label="Buscar" variant="outlined" />
          )}
          onChange={handleAutocomplete}
          name="autocomplete"
          size="small"
        />
      )}

      {Preguntado !== 0 &&
        PreguntasMap.filter(
          func => Number(func.id_pregunta) === Number(Preguntado)
        ).map((dato, i) => (
          <Accordion defaultActiveKey="0" key={i} className="mt-3">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={dato.id_pregunta}
                className="card-header-faq-ss"
              >
                {dato.desc_pregunta}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={dato.id_pregunta} className="show">
                <Card.Body className="card-body-faq-ss text-justify ">
                  {dato.desc_resp}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ))}
    </>
  )
}

export default PrestadoresSalud
